import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, List, ListItem, Link, SectionTitle, Title, Text, theme } from "@nulogy/components";
import { COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Shadows`}</h1>
    <p>{`There are three shadows available to provide depth to an interface.`}</p>
    <Box bg="lightGrey" p="x6" maxWidth={COPY_WIDTH} m="0 auto" mdxType="Box">
  <Box p="x2" bg="white" boxShadow="small" mb="x2" mdxType="Box">
    <Text fontWeight="bold" mdxType="Text">Small</Text>
    <Text fontFamily="mono" fontSize="smaller" mdxType="Text">
      {theme.shadows.small}
    </Text>
  </Box>
  <Box py="x4" px="x2" bg="white" boxShadow="medium" mb="x2" mdxType="Box">
    <Text fontWeight="bold" mdxType="Text">Medium</Text>
    <Text fontFamily="mono" fontSize="smaller" mdxType="Text">
      {theme.shadows.medium}
    </Text>
  </Box>
  <Box py="x8" px="x2" bg="white" boxShadow="large" mdxType="Box">
    <Text fontWeight="bold" mdxType="Text">Large</Text>
    <Text fontFamily="mono" fontSize="smaller" mdxType="Text">
      {theme.shadows.large}
    </Text>
  </Box>
    </Box>
    <h2>{`States`}</h2>
    <Box maxWidth={COPY_WIDTH} m="0 auto" mdxType="Box">
  <Box p="x2" bg="white" boxShadow="focus" mb="x2" borderRadius="medium" mdxType="Box">
    <Text fontWeight="bold" mdxType="Text">Focus</Text>
    <Text fontFamily="mono" fontSize="smaller" mdxType="Text">
      {theme.shadows.focus}
    </Text>
  </Box>
  <Box p="x2" bg="white" boxShadow="error" mb="x2" borderRadius="medium" mdxType="Box">
    <Text fontWeight="bold" mdxType="Text">Error</Text>
    <Text fontFamily="mono" fontSize="smaller" mdxType="Text">
      {theme.shadows.error}
    </Text>
  </Box>
    </Box>
    <h2>{`Usage`}</h2>
    <p>{`Shadows can be added to the Box component using the `}<inlineCode parentName="p">{`boxShadow`}</inlineCode>{` prop. Focus and Error states come built-in to inputs already, and all of our shadow values are stored in the `}<a parentName="p" {...{
        "href": "/theme"
      }}>{`theme`}</a>{` for referencing anywhere else.`}</p>
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/box"
        }}>{`Box component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/theme"
        }}>{`NDS Theme`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      